<template>
  <section class="profile">
    <div class="page-header">
      <h3 class="page-title">
        Produit
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Catalogue</a></li>
          <li class="breadcrumb-item active" aria-current="page">Produit</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <div class="border-bottom text-center pb-4">
                  <b-carousel id="carousel" controls indicators background="#ababab" :interval="3000" v-model="slide2">
                    <!-- carousel image -->
                      <b-carousel-slide v-for="image in productDetails.images" :key="image.id">
                        <picture v-b-modal="image.ulid" slot="img">
                          <source :srcset="image.content.webpImage" type="image/webp">
                          <img :src="image.content.originalImage" alt="ne marche pas ">
                        </picture>
                        <!-- agrandir l'image -->
                        <b-modal :id="image.ulid" hide-footer hide-header>
                          <picture v-b-modal="image.ulid" v-if="image">
                            <source :srcset="image.content.webpImage" type="image/webp">
                            <img :src="image.content.originalImage" alt="">
                          </picture>
                          <img v-else  class="product_image" src="https://fakeimg.pl/300x200"  alt="Card image">
                          <div class="d-flex justify-content-center">
                           <button class="btn btn-gradient-danger mt-2" @click="deleteImage(image)" ><i class="fa fa-trash"></i></button>
                          </div>
                        </b-modal>
                      </b-carousel-slide>
                  </b-carousel>
                  
                  <p>{{productDetails.description}}</p>

                  <!-- ajouter une image -->
                  <div class="d-block d-sm-flex justify-content-center">
                    <add-image
                    :modal="'Ajout image'"
                    @saveImage="saveImage($event['ulid'])"
                    />
                  </div>
                </div>
                <div class="py-4">
                  <p class="clearfix">
                    <span class="float-left">
                      Stock
                    </span>
                    <span class="float-right text-muted">
                      {{productDetails.stock}}
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Volume
                    </span>
                    <span class="float-right text-muted">
                      {{productDetails.volume}}
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Poids
                    </span>
                    <span class="float-right text-muted">
                      {{productDetails.weight}}
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Lieu d'expédition
                    </span>
                    <span class="float-right text-muted">
                      {{productDetails.shippinglocation}}
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Date de publication
                    </span>
                    <span class="float-right text-muted">
                      {{productDetails.datepublished}}
                    </span>
                  </p>
                </div>
                <b-button variant="gradient-primary btn-block" @click="editProduct">Modifier le produit</b-button>
              </div>
              <div class="col-lg-8">
                <div class="d-lg-flex justify-content-between">
                  <div>
                    <h3 class="mt-3 mt-lg-0">{{catalogueDetails.name}}</h3>
                    <div class="d-flex align-items-center">
                      <h3 class="mb-0 mr-2 text-muted mt-3 mt-lg-0">{{productDetails.price *1.2}} €</h3>
                      <star-rating class="ml-3" 
                      v-bind:increment="0.5" 
                      v-bind:rating="averageRating" 
                      v-bind:max-rating="5"
                      v-bind:star-size="20" 
                      v-bind:show-rating="false"
                      v-bind:read-only="true" 
                      :active-color="'#b66dff'"
                        >
                      </star-rating>
                    </div>
                  </div>
                 

                  <div class="mt-3 mt-lg-0">
                    <b-button variant="outline-secondary btn-icon">
                      <i class="mdi mdi-comment-processing"></i>
                    </b-button>
                  </div>
                </div>
                <div class="mt-4 py-2 border-top border-bottom">
                  <ul class="nav profile-navbar">
                    <li class="nav-item">
                      <a class="nav-link active" href="javascript:void(0);">
                        <i class="mdi mdi-comment"></i>
                        Voir tous les avis
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="profile-feed mh-500 overflow-auto">
                  <div class="d-flex align-items-start profile-feed-item" v-for="(rating, i) in userratings" :key='i'>
                    <img src="../../../assets/images/faces/face13.jpg" alt="profile" class="img-sm rounded-circle"/>
                    <div class="ml-4">
                      <h6>
                        Pierre Boisard ({{rating.ulid}})
                        <small class="ml-4 text-muted"><i class="mdi mdi-clock mr-1"></i>{{rating.datepublished}}</small>
                      </h6>
                      <p>
                        {{rating.comment}}
                      </p>
                      <p class="small text-muted mt-2 mb-0">
                        <span>
                          <i class="mdi mdi-star mr-1"></i>{{rating.rating}}
                        </span>
                        <!-- <span class="ml-2">
                          <i class="mdi mdi-comment mr-1"></i>11
                        </span> -->
                        <span class="ml-2 pointer" @click="deleteCommentAlert(rating)" title="suppression du commentaire">
                          <i class="mdi mdi-delete"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
const axios = require ('axios').default
import StarRating from 'vue-star-rating'
import addImage from '../../../components/functions/add-image.vue'
export default {
  name: 'catalogueDetails',
  components: {
    StarRating,
    addImage
  },
  data () {
      return {
          link: 'essai',
          userratings:[],
          StarRating:'',
          catalogueDetails:'',
          productDetails:'',
          // products: [],
          averageRating:0,
          ulid:'',
          slide2: 0
      }
  },
  computed: {
  },
  methods: {
    getProduct(ulid) {
      axios.get(`products/${ulid}`)
    .then(resProduct => {
      this.productDetails=resProduct.data
      console.log("productDetails", this.productDetails);
    })
    .catch(errProduct => console.log('errProduct', errProduct))
    },
    //add products in cart
    addProducts() {
        this.$store.dispatch('ADD_PRODUCTS',this.catalogueDetails)
    },
    getUserRatings(){
    //get userrating
    axios.get(`products/${this.ulid}`)
    .then(async res=> {
      console.log('userratings', res)
      // this.userratings= res.data.result
      let userratings= res.data.result
      console.log('userratings before sort', userratings);
      userratings= userratings.slice().sort(( a, b) => new Date(b.datepublished) - new Date(a.datepublished))
      console.log('userratings after sort', userratings);
      this.userratings=userratings

// average rating
      let totalRatings=0
     for(const rate of this.userratings){
       totalRatings= totalRatings + rate.rating
     }
     this.averageRating= totalRatings/this.userratings.length
      })
    .catch(err=> console.log(err))
    },
    deleteComment(comment) {
      axios.delete(`userratings/${comment.ulid}`,{
        headers: {
          'X-AUTH-TOKEN': localStorage.getItem('token')
        }
      })
      .then(delComment=> {
        console.log(delComment)
        this.getUserRatings()
      })
      .catch(errDelComment=> {
        alert('erreur de suppression', errDelComment)
      })
    },
    deleteCommentAlert (comment) {
      this.$swal({
        title: 'Êtes-vous sûr?',
        text: 'Voulez-vous supprimer cet avis?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, Supprimer',
        cancelButtonText: 'Annuler',
      })
      .then((result) => {
        if (result.value) {
          this.deleteComment(comment)
          this.$swal(
            'Supprimé!',
            'L\'avis a bien été supprimé',
            'success'
          )
        }
      })
    },
    //edit
      editProduct(){
      // console.log('edit product',product)
      this.$router.push ( {
          name: 'editCatalogue',
          params: {
          ulid: this.ulid,
          }
      })
    },
    deleteImage(image){
      axios.put(`products/${this.productDetails.ulid}`,
      {
        remove_image:image.ulid
      },
      {
        headers:{
          'X-AUTH-TOKEN': localStorage.getItem('token')
        }
      }
      )
      .then(resDeleteImage => {
        console.log(resDeleteImage)
      })
      .catch(errDeleteImage => {
        console.log(errDeleteImage)
      })
    },
    saveImage(ulidImage){
      axios.put(`products/${this.productDetails.ulid}`, {
           add_image: ulidImage,
          },
          {
          headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token')
          }
      })
          .then(()=> {
            window.location.reload()
          })
          .catch(err => console.log ('errorPUT', err))
    }
  },
  mounted () {
    //get params data from catalogue
    this.ulid=this.$route.params.ulid
    //get products
    this.getProduct(this.ulid)
    // this.getUserRatings()
  }
}
</script>

<style scoped>
img {
  max-width: 100%;
  object-fit: cover
}
</style>